
import { Options, Vue } from "vue-class-component";
import {
  get_article_visiable,
  get_article_any,
  get_article_list,
  get_all_article_list,
} from "@/api/article.api";
import { mapState } from "vuex";
@Options({
  components: {},
  data() {
    return {
      article: null,
      article_list: [],
      articleId: "",
      titles: [],
      useToc: true,
      toc_title: "目录导航",
      showBackTopIcon: false,
    };
  },
  computed: {
    ...mapState("user", ["isLogin"]),
    get_article() {
      if (this.isLogin) {
        return get_article_any;
      } else {
        return get_article_visiable;
      }
    },
    get_list() {
      if (this.isLogin) {
        return get_all_article_list;
      } else {
        return get_article_list;
      }
    },
  },
  async created() {
    // console.log("loginstate:", this.isLogin);
    this.articleId = this.$route.params.articleId;
    if (this.articleId && typeof +this.articleId == "number") {
      this.article = await this.get_article({
        articleId: this.articleId,
      });
      document.title = `${this.article.title} | leoyi编程日志`;
      this.article_list = (
        await this.get_list({
          userId: this.article.userId,
          fieldId: this.article.fieldId,
        })
      ).list;
    }
    setTimeout(() => {
      const anchors =
        this.$refs.preview.$el.querySelectorAll("h1,h2,h3,h4,h5,h6");
      // console.log("anchors:", anchors);
      const titles = Array.from(anchors).filter(
        (title: any) => !!title.innerText.trim()
      );

      if (!titles.length) {
        this.titles = [];
        return;
      }

      const hTags = Array.from(
        new Set(titles.map((title: any) => title.tagName))
      ).sort();

      this.titles = titles.map((el: any) => ({
        title: el.innerText,
        lineIndex: el.getAttribute("data-v-md-line"),
        indent: hTags.indexOf(el.tagName),
      }));
    }, 0);
  },
  mounted() {
    window.onscroll = () => {
      if (window.scrollY > 200) {
        this.showBackTopIcon = true;
      } else {
        this.showBackTopIcon = false;
      }
    };
  },
  methods: {
    // ...mapActions("user", ["requestUserInfo"]),
    handleAnchorClick(anchor: any) {
      const { preview } = this.$refs;
      const { lineIndex } = anchor;

      const heading = preview.$el.querySelector(
        `[data-v-md-line="${lineIndex}"]`
      );

      if (heading) {
        preview.scrollToTarget({
          target: heading,
          scrollContainer: window,
          top: 60,
        });
      }
    },
    gotoSearch(type: string, data: any) {
      this.$router.push({
        name: "search",
        query: {
          [type]: data,
        },
      });
    },
    // 打开编辑页面
    gotoEdit(articleId: number) {
      // // console.log(this.$router)
      window.open(
        window.location.origin + `/article/edit/${articleId}`,
        "_blank"
      );
    },
    setToc() {
      if (this.useToc == false) {
        // 开启toc
        let _titles = [...this.titles];
        this.toc_title = "";
        this.titles = [];
        setTimeout(() => {
          this.titles = [..._titles];
          this.toc_title = "目录导航";
        }, 300);
      }
      this.useToc = this.useToc ? false : true;
    },
    // 跳转到顶部
    backtop() {
      // console.log("backtop");
      window.scrollTo(0, 0);
    },
    // 打开页面
    openPage(articleId: number) {
      // // console.log(this.$router)
      window.open(window.location.origin + `/article/${articleId}`, "_blank");
    },
  },
})
export default class ArticleDetail extends Vue {}
